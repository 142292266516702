import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import {
    Box,
    LinearProgress,
    Typography,
    Tabs,
    TabList,
    Tab,
    TabPanel,
} from '@mui/joy';

import GiganticEventTab from '../components/GiganticEventTab';
import SeeticketsEventTab from '../components/SeeticketsEventTab';
import TicketwebEventTab from '../components/TicketwebEventTab';
import SkiddleDVEventTab from '../components/SkiddleDVTab';
import SkiddleDPEventTab from '../components/SkiddleDPTab';
import DiceEventTab from '../components/DiceTab';

export default function EventsPage() {
    const [events, setEvents] = React.useState();

    React.useEffect(() => {
        fetch(`https://api.dashboard.divergentpromotions.com/events`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then(res => res.json())
            .then(json => {
                if (!json.error) {
                    setEvents(json);
                } else {
                    console.error(json.error);
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            });
    }, []);

    return (
        <Box sx={{ px: 4, position: 'relative', width: '100%', height: 'calc(100% - 89.6px);' }}>
            <AnimatePresence>
                {!events
                    ?
                    <motion.div
                        key="loading"
                        initial={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 10 }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100%", backgroundColor: 'background.body' }}>
                            <Typography level="h1">{import.meta.env.VITE_CAD_NAME}</Typography>
                            <Typography level="h4">Loading events</Typography>
                            <Box sx={{ width: 400, mt: 4 }}>
                                <LinearProgress variant="solid" />
                            </Box>
                        </Box>
                    </motion.div>
                    :
                    <Tabs sx={{ backgroundColor: 'white' }}>
                        <TabList
                            sx={{
                                overflow: 'auto',
                                scrollSnapType: 'x mandatory',
                            }}
                        >
                            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start' }}>Gigantic</Tab>
                            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start' }}>Seetickets</Tab>
                            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start' }}>Ticketweb</Tab>
                            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start' }}>Skiddle DV</Tab>
                            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start' }}>Skiddle DP</Tab>
                            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start' }}>Dice</Tab>
                        </TabList>
                        <TabPanel value={0}>
                            <Tabs sx={{ backgroundColor: 'white' }}>
                                <TabList
                                    sx={{
                                        overflow: 'auto',
                                        scrollSnapType: 'x mandatory',
                                    }}
                                >
                                    {events.gigantic.map(event => (
                                        <Tab key={event.id} sx={{ flex: 'none', scrollSnapAlign: 'start' }}>{event.name}</Tab>
                                    ))}
                                </TabList>
                                {events.gigantic.map((event, index) => (
                                    <TabPanel value={index}>
                                        <GiganticEventTab name={event.name} id={event.id} />
                                    </TabPanel>
                                ))}
                            </Tabs>
                        </TabPanel>
                        <TabPanel value={1}>
                            <Tabs sx={{ backgroundColor: 'white' }}>
                                <TabList
                                    sx={{
                                        overflow: 'auto',
                                        scrollSnapType: 'x mandatory',
                                    }}
                                >
                                    {events.seetickets.map(event => (
                                        <Tab key={event.id} sx={{ flex: 'none', scrollSnapAlign: 'start' }}>{event.name}</Tab>
                                    ))}
                                </TabList>
                                {events.seetickets.map((event, index) => (
                                    <TabPanel value={index}>
                                        <SeeticketsEventTab name={event.name} id={event.id} />
                                    </TabPanel>
                                ))}
                            </Tabs>
                        </TabPanel>
                        <TabPanel value={2}>
                            <Tabs sx={{ backgroundColor: 'white' }}>
                                <TabList
                                    sx={{
                                        overflow: 'auto',
                                        scrollSnapType: 'x mandatory',
                                    }}
                                >
                                    {events.ticketweb.map(event => (
                                        <Tab key={event.id} sx={{ flex: 'none', scrollSnapAlign: 'start' }}>{event.name}</Tab>
                                    ))}
                                </TabList>
                                {events.ticketweb.map((event, index) => (
                                    <TabPanel value={index}>
                                        <TicketwebEventTab name={event.name} id={event.id} />
                                    </TabPanel>
                                ))}
                            </Tabs>
                        </TabPanel>
                        <TabPanel value={3}>
                            <Tabs sx={{ backgroundColor: 'white' }}>
                                <TabList
                                    sx={{
                                        overflow: 'auto',
                                        scrollSnapType: 'x mandatory',
                                    }}
                                >
                                    {events.skiddle_dv.map(event => (
                                        <Tab key={event.id} sx={{ flex: 'none', scrollSnapAlign: 'start' }}>{event.name}</Tab>
                                    ))}
                                </TabList>
                                {events.skiddle_dv.map((event, index) => (
                                    <TabPanel value={index}>
                                        <SkiddleDVEventTab name={event.name} id={event.id} />
                                    </TabPanel>
                                ))}
                            </Tabs>
                        </TabPanel>
                        <TabPanel value={4}>
                            <Tabs sx={{ backgroundColor: 'white' }}>
                                <TabList
                                    sx={{
                                        overflow: 'auto',
                                        scrollSnapType: 'x mandatory',
                                    }}
                                >
                                    {events.skiddle_dp.map(event => (
                                        <Tab key={event.id} sx={{ flex: 'none', scrollSnapAlign: 'start' }}>{event.name}</Tab>
                                    ))}
                                </TabList>
                                {events.skiddle_dp.map((event, index) => (
                                    <TabPanel value={index}>
                                        <SkiddleDPEventTab name={event.name} id={event.id} />
                                    </TabPanel>
                                ))}
                            </Tabs>
                        </TabPanel>
                        <TabPanel value={5}>
                            <Tabs sx={{ backgroundColor: 'white' }}>
                                <TabList
                                    sx={{
                                        overflow: 'auto',
                                        scrollSnapType: 'x mandatory',
                                    }}
                                >
                                    {events.dice.map(event => (
                                        <Tab key={event.id} sx={{ flex: 'none', scrollSnapAlign: 'start' }}>{event.name}</Tab>
                                    ))}
                                </TabList>
                                {events.dice.map((event, index) => (
                                    <TabPanel value={index}>
                                        <DiceEventTab name={event.name} id={event.id} />
                                    </TabPanel>
                                ))}
                            </Tabs>
                        </TabPanel>
                    </Tabs>
                }
            </AnimatePresence>
        </Box>
    )
}
