import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, FormControl, FormLabel, Input, Button } from '@mui/joy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function UsersPage() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetch(`https://api.dashboard.divergentpromotions.com/users`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then(res => res.json())
            .then(json => {
                if (!json.error) {
                    setUsers(json.users);
                } else {
                    console.error(json.error);
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            });
    }, []);

    const changePassword = (id) => {
        withReactContent(Swal).fire({
            title: "Enter new password",
            input: 'text',
            preConfirm: () => {
                fetch('https://api.dashboard.divergentpromotions.com/users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token'),
                    },
                    body: JSON.stringify({
                        id: id,
                        password: Swal.getInput()?.value,
                    }),
                })
                    .then(res => res.json())
                    .then(json => {
                        if (!json.error) {
                            if (json.success) {
                                toast.success('Password updated');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            } else {
                                toast.error('Failed to update password');
                            }
                        } else {
                            console.error(json.error);
                            localStorage.removeItem('token');
                            window.location.reload();
                        }
                    });
            },
        })
    }

    const createUser = () => {
        let emailInput, firstNameInput, lastNameInput, passwordInput;

        withReactContent(Swal).fire({
            title: 'Create New User',
            html: `
                <input type="email" id="email" class="swal2-input" placeholder="Email">
                <input type="text" id="first_name" class="swal2-input" placeholder="First Name">
                <input type="text" id="last_name" class="swal2-input" placeholder="Last Name">
                <input type="text" id="password" class="swal2-input" placeholder="Password">
            `,
            confirmButtonText: 'Create User',
            focusConfirm: false,
            didOpen: () => {
                const popup = Swal.getPopup();
                emailInput = popup.querySelector('#email');
                firstNameInput = popup.querySelector('#first_name');
                lastNameInput = popup.querySelector('#last_name');
                passwordInput = popup.querySelector('#password');

                emailInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                firstNameInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                lastNameInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                passwordInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
            },
            preConfirm: () => {
                const email = emailInput.value;
                const first_name = firstNameInput.value;
                const last_name = lastNameInput.value;
                const password = passwordInput.value;

                if (!email || !first_name || !last_name || !password) {
                    Swal.showValidationMessage(`Please fill in all fields`);
                    return false;
                }

                return { email, first_name, last_name, password };
            },
        }).then((result) => {
            if (result.isConfirmed && result.value) {
                fetch('https://api.dashboard.divergentpromotions.com/users', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token'),
                    },
                    body: JSON.stringify(result.value),
                })
                    .then(res => res.json())
                    .then(json => {
                        if (!json.error) {
                            if (json.success) {
                                toast.success('User created');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            } else {
                                throw new Error('Failed to create user');
                            }
                        } else {
                            console.error(json.error);
                            localStorage.removeItem('token');
                            throw new Error('Authentication error');
                        }
                    })
                    .catch(error => {
                        toast.error(`Request failed: ${error.message}`);
                    });
            }
        });
    };

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'First Name',
            selector: row => row.first_name,
        },
        {
            name: 'Last Name',
            selector: row => row.last_name,
        },
        {
            name: 'Actions',
            cell: row => <Button onClick={() => changePassword(row.id)}>Change Password</Button>,
        }
    ];

    return (
        <Box sx={{ px: 4 }}>
            <Button onClick={() => createUser()}>Create User</Button>
            <DataTable
                columns={columns}
                data={users}
                pagination
            />
        </Box>
    );
}