import React from 'react';
import { useNavigate, Outlet } from "react-router-dom";
import { UserContext } from '../context/UserContext';

import {
    Sheet,
    Box,
    Typography,
    Button,
    Avatar,
    Dropdown,
    MenuButton,
    Menu,
    MenuItem,
} from '@mui/joy';

export default function Navbar() {
    const { user, isLoading } = React.useContext(UserContext);
    const navigate = useNavigate();

    return (
        <Box sx={{ width: "100%", height: "100vh" }}>
            <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 0,
                    mb: 2,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography level="h4">{import.meta.env.VITE_CAD_NAME}</Typography>
                    </Box>
                    <Button variant="soft" color="neutral" onClick={() => navigate('/dashboard')}>Home</Button>
                    <Button variant="soft" color="neutral" onClick={() => navigate('/dashboard/events')}>Events</Button>
                    <Button variant="soft" color="neutral" onClick={() => navigate('/dashboard/accounts')}>Accounts</Button>
                    <Button variant="soft" color="neutral" onClick={() => navigate('/dashboard/users')}>Users</Button>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Dropdown>
                        <MenuButton sx={{ p: 0, border: 'none', borderRadius: '9999px' }}>
                            <Avatar alt={user && user.email} src={user && `https://www.gravatar.com/avatar/${user.hash}?s=40&d=mp`} sx={{ cursor: 'pointer' }} />
                        </MenuButton>
                        <Menu>
                            <MenuItem>Settings</MenuItem>
                            <MenuItem onClick={() => { localStorage.removeItem('token'); navigate('/') }}>Logout</MenuItem>
                        </Menu>
                    </Dropdown>
                </Box>
            </Sheet>
            {!isLoading &&
                <Outlet />
            }
        </Box>
    );
};
