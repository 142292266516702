import React from 'react';

import Box from '@mui/joy/Box';

export default function SettingsPage() {

    return (
        <Box sx={{ px: 4 }}>

        </Box>
    )
}
