import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { UserContext } from '../context/UserContext';

import {
    Box,
    LinearProgress,
    Typography,
} from '@mui/joy';

export default function ProtectedRoute() {
    const { isLoading } = React.useContext(UserContext);

    return (
        <AnimatePresence>
            {isLoading &&
                <motion.div
                    key="loading"
                    initial={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 99999 }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100%", backgroundColor: 'background.body' }}>
                        <Typography level="h1">{import.meta.env.VITE_CAD_NAME}</Typography>
                        <Typography level="h4">Loading user</Typography>
                        <Box sx={{ width: 400, mt: 4 }}>
                            <LinearProgress variant="solid" />
                        </Box>
                    </Box>
                </motion.div>
            }
        </AnimatePresence>
    )
}