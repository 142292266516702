import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';

import { UserContext } from '../context/UserContext';

import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Alert from '@mui/joy/Alert';
import Button from '@mui/joy/Button';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

export default function LoginPage() {
    const { login, isLoginLoading, error, emailError, passwordError } = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') setEmail(value);
        if (name === 'password') setPassword(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        login(email, password);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'justify-between', height: '100vh' }}>
            <Box sx={{
                position: 'relative',
                width: '50%',
                height: '100%',
                backgroundImage: 'url("/login_image.jpeg")',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                    <Box>
                        {import.meta.env.VITE_LOGIN_SCREEN_SLOGAN.split(' ').map((word, index) => (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: (index * 0.5) + 1, duration: 0.5 }}
                            >
                                <Typography key={index} level="h1" sx={{ color: 'white', fontWeight: 700, fontSize: '100px' }}>{word}</Typography>
                            </motion.div>
                        ))}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '50%' }}>
                <Card variant="outlined">
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 2, width: 400 }} component="form" onSubmit={handleSubmit}>
                        {error &&
                            <Alert color="danger" variant="soft" sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                {error}
                            </Alert>
                        }
                        <Typography level="h2">
                            Login to your account
                        </Typography>
                        <FormControl error={emailError}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                                error={!!emailError}
                                InputProps={{
                                    readOnly: isLoginLoading,
                                }}
                            />
                            {emailError &&
                                <FormHelperText>
                                    <InfoOutlined />
                                    {emailError}
                                </FormHelperText>
                            }
                        </FormControl>
                        <FormControl error={passwordError}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                type="password"
                                name="password"
                                autoComplete="password"
                                value={password}
                                onChange={handleInputChange}
                                error={!!passwordError}
                                InputProps={{
                                    readOnly: isLoginLoading,
                                }}
                            />
                            {passwordError &&
                                <FormHelperText>
                                    <InfoOutlined />
                                    {passwordError}
                                </FormHelperText>
                            }
                        </FormControl>
                        <Button type="submit" loading={isLoginLoading}>
                            Login
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}
