import React from 'react';
import DataTable from 'react-data-table-component';

import {
    Box,
    Typography,
    Button,
    LinearProgress,
    Input,
} from '@mui/joy';

const columns = [
    {
        name: 'Event',
        selector: row => row.event,
    },
    {
        name: 'Location',
        selector: row => row.location,
    },
    {
        name: 'Time',
        selector: row => row.time,
    },
    {
        name: 'Event Date',
        selector: row => row.event_date,
    },
    {
        name: 'Ticket Type',
        selector: row => row.ticket_type,
    },
    {
        name: 'Face Value',
        selector: row => row.face_value,
        sortable: true,
    },
    {
        name: 'Sold',
        selector: row => row.sold,
        sortable: true,
    },
    {
        name: 'Allocated',
        selector: row => row.allocation,
    },
];

export default function TicketwebEventTab({ name, id }) {
    const [isLoading, setIsLoading] = React.useState(true);
    const [events, setEvents] = React.useState([]);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [updatingData, setUpdatingData] = React.useState({});
    const [filter, setFilter] = React.useState('');
    const [filteredEvents, setFilteredEvents] = React.useState(events.rows);

    React.useEffect(() => {
        fetch(`https://api.dashboard.divergentpromotions.com/events/ticketweb/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then(res => res.json())
            .then(json => {
                if (!json.error) {
                    setEvents(json);
                    setFilteredEvents(json.rows);
                    setIsLoading(false);
                } else {
                    console.error(json.error);
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            })
    }, [])

    const update = () => {
        setIsUpdating(true);
        fetch(`https://api.dashboard.divergentpromotions.com/events/update/ticketweb/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        }).then(function (response) {
            let reader = response.body.getReader();
            let decoder = new TextDecoder();
            return readData();
            function readData() {
                return reader.read().then(function ({ value, done }) {
                    let newData = decoder.decode(value, { stream: !done });
                    if (newData) {
                        setUpdatingData(JSON.parse(newData));
                    }
                    if (done) {
                        setIsUpdating(false);
                        setUpdatingData({});
                        refresh();
                        return;
                    }
                    return readData();
                });
            }
        });
    }

    const refresh = () => {
        setIsLoading(true);
        fetch(`https://api.dashboard.divergentpromotions.com/events/ticketweb/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then(res => res.json())
            .then(json => {
                if (!json.error) {
                    setEvents(json);
                    setFilteredEvents(json.rows);
                    setIsLoading(false);
                } else {
                    console.error(json.error);
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            })
    }

    const handleFilterChange = (e) => {
        const value = e.target.value;
        setFilter(value);

        const newFilteredEvents = events.rows.filter(event =>
            Object.values(event).some(val =>
                String(val).toLowerCase().includes(value.toLowerCase())
            )
        );

        setFilteredEvents(newFilteredEvents);
    };

    return (
        <Box>
            <Typography level="h3">{name}</Typography>
            <Typography level="body-md">Last updated: {events.last_updated}</Typography>
            <Button onClick={() => update()} sx={{ my: 1 }}>Force update now</Button>
            <Input placeholder="Filter events" sx={{ width: 300 }} value={filter} onChange={handleFilterChange} />
            <br></br>
            {isUpdating
                ?
                updatingData.out_of ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', gap: 2 }}>
                        <Typography level="h3">{updatingData.message}</Typography>
                        <LinearProgress determinate value={(updatingData.done / updatingData.out_of) * 100} sx={{ width: 400 }} />
                    </Box>
                    :
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', gap: 2 }}>
                        <Typography level="h3">{updatingData.message}</Typography>
                        <LinearProgress determinate value={updatingData.reached ? 100 : 1} sx={{ width: 400 }} />
                    </Box>
                :
                isLoading ?
                    <LinearProgress />
                    :
                    <DataTable
                        columns={columns}
                        data={filteredEvents}
                        pagination
                        noDataComponent={<Typography level="body-md">No events found. If you feel this is incorrect, please use the button above to force a sync.</Typography>}
                    />
            }
        </Box>
    )
}
