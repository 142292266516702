import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { ToastContainer } from 'react-toastify';
import CssBaseline from '@mui/joy/CssBaseline';

import 'react-toastify/dist/ReactToastify.css';

import { UserProvider } from './context/UserContext';

import LoginPage from './pages/LoginPage';

import DashboardPage from './pages/DashboardPage';
import EventsPage from './pages/EventsPage';
import SettingsPage from './pages/SettingsPage';
import AccountsPage from './pages/AccountsPage';
import UsersPage from './pages/UsersPage';

import Navbar from './components/Navbar';

import ProtectedRoute from './components/ProtectedRoute';

import './index.css';
import '@fontsource/inter';

const theme = extendTheme({
    palette: {
        primary: {
            main: "#8026ff",
        },
    },
});

Sentry.init({
    dsn: "https://a95d1478a62009f523958bdceab0cde8@o4507879763607552.ingest.de.sentry.io/4507879773503568",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <ToastContainer />
        <CssVarsProvider theme={theme}>
            <CssBaseline />
            <UserProvider>
                <Routes>
                    <Route path="/">
                        <Route index element={<LoginPage />} />

                        <Route path="dashboard" element={<><Navbar /><ProtectedRoute /></>}>
                            <Route index element={<DashboardPage />} />
                            <Route path="events" element={<EventsPage />} />
                            <Route path="settings" element={<SettingsPage />} />
                            <Route path="accounts" element={<AccountsPage />} />
                            <Route path="users" element={<UsersPage />} />
                        </Route>

                        <Route path="*" element={<>404 Not Found</>} />
                    </Route>
                </Routes>
            </UserProvider>
        </CssVarsProvider>
    </BrowserRouter>,
)
