import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, FormControl, FormLabel, Input, Button } from '@mui/joy';
import { toast } from 'react-toastify';

export default function AccountsPage() {
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        fetch(`https://api.dashboard.divergentpromotions.com/accounts`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then(res => res.json())
            .then(json => {
                if (!json.error) {
                    setAccounts(json.accounts);
                    setTimeout(() => {
                        for (const account of json.accounts) {
                            document.getElementById(`email-${account.id}`).value = account.email;
                            document.getElementById(`password-${account.id}`).value = account.password;
                        }
                    }, 10)
                } else {
                    console.error(json.error);
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            });
    }, []);

    const updateAccount = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        fetch(`https://api.dashboard.divergentpromotions.com/accounts/${formData.get('id')}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
            body: JSON.stringify({
                email: formData.get("email"),
                password: formData.get("password"),
            }),
        })
            .then(res => res.json())
            .then(json => {
                if (!json.error) {
                    if (json.success) {
                        toast.success('Account updated');
                    } else {
                        toast.error('Failed to update account');
                    }
                } else {
                    console.error(json.error);
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            });
    };

    return (
        <Box sx={{ px: 4 }}>
            {accounts.map(account => (
                <Card key={account.id} sx={{ my: 4, p: 2 }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 2, width: 400 }} component="form" onSubmit={updateAccount}>
                        <Typography level="h4" sx={{ mb: 2 }}>Account: {account.name}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Input type="hidden" name="id" sx={{ display: 'none' }} value={account.id} />
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    label="Email"
                                    name="email"
                                    slotProps={{
                                        input: {
                                            id: `email-${account.id}`,
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    label="Password"
                                    name="password"
                                    slotProps={{
                                        input: {
                                            id: `password-${account.id}`,
                                        }
                                    }}
                                />
                            </FormControl>
                            <Button sx={{ width: 'fit-content' }} type="submit">Update Account</Button>
                        </Box>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
}