import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoginLoading, setIsLoginLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            localStorage.removeItem('token');
            return navigate("/");
        }
        fetch(`${import.meta.env.VITE_BACKEND_URL}/auth/me`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    navigate("/");
                } else {
                    setUser(json);
                }
                setIsLoading(false);
            });
    }, []);

    const login = (email, password) => {
        setIsLoginLoading(true);
        setError('');
        setEmailError('');
        setPasswordError('');

        if (!email) {
            setEmailError('You must input an email');
            setIsLoginLoading(false);
            return;
        }
        if (!password) {
            setPasswordError('You must input a password');
            setIsLoginLoading(false);
            return;
        }

        fetch(`${import.meta.env.VITE_BACKEND_URL}/auth/login`, {
            body: JSON.stringify({ email, password }),
            headers: { 'Content-Type': 'application/json' },
            method: 'POST'
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    setError(json.error);
                    setIsLoginLoading(false);
                } else {
                    if (json.success) {
                        if (json.token) {
                            localStorage.setItem('token', json.token);
                            setUser(json.user);
                            navigate("/dashboard");
                            setIsLoginLoading(false);
                            setIsLoading(false);
                        } else {
                            setError("The backend did not return a token, please contact a website administrator");
                            setIsLoginLoading(false);
                        }
                    } else {
                        setError("The backend did not return an error or success, please contact a website administrator");
                        setIsLoginLoading(false);
                    }
                }
            })
            .catch(e => {
                setError("There was an error contacting the backend, please contact a website administrator");
                setIsLoginLoading(false);
            });
    };

    return (
        <UserContext.Provider value={{ user, isLoginLoading, isLoading, error, emailError, passwordError, login }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
